<template>
	<div>
		<div class="row-layout">
			<h3>Plano</h3>
			<b-row>
				<b-col md="4">
					<b-card class="card-subscription" sub-title="Seu plano">
						<h4 v-if="!dataLoaded">Carregando...</h4>
						<h4 v-else>{{ planSubscribe.name }}</h4>
					</b-card>
				</b-col>

				<b-col>
					<b-card class="card-subscription" sub-title="Outros planos">
						<h4>
							<router-link to="/">Visualizar</router-link>
							<router-link to="/">
								<img src="/icons/export2.svg" alt="" />
							</router-link>
						</h4>
					</b-card>
				</b-col>

				<b-col>
					<b-card class="card-subscription" sub-title="Data da assinatura">
						<h4 v-if="!dataLoaded">Carregando...</h4>
						<h4 v-else>{{ createAt }}</h4>
					</b-card>
				</b-col>

				<b-col>
					<b-card class="card-subscription" sub-title="Médicos ativos">
						<h4 class="green" v-if="!dataLoaded">
							-/- <img src="/icons/doctor-green.svg" alt="" />
						</h4>

						<h4 class="green" v-else>
							{{ currentUsers }}/{{ seats }}
							<img src="/icons/doctor-green.svg" alt="" />
						</h4>
					</b-card>
				</b-col>
			</b-row>
		</div>

		<div class="row-layout">
			<h3>Pagamento</h3>
			<b-row>
				<b-col md="5">
					<b-card class="card-subscription" sub-title="Suporte">
						<h4 class="email">
							financeiro@eyecarehealth.com.br
							<img src="/icons/mail-support.svg" alt="" />
						</h4>
					</b-card>
				</b-col>

				<b-col md="4">
					<b-card class="card-subscription" sub-title="Pagamento">
						<h4 v-if="!dataLoaded">Carregando...</h4>
						<h4 v-else>{{ paymentMethod.name }}</h4>
					</b-card>
				</b-col>

				<b-col>
					<b-card class="card-subscription" sub-title="Renova em">
						<h4 v-if="!dataLoaded">Carregando...</h4>
						<h4 v-else>{{ nextBilling }}</h4>
					</b-card>
				</b-col>
			</b-row>
		</div>

		<div>
			<h3>Transações</h3>
			<b-card class="card-table">
				<b-table-simple>
					<tbody>
						<tr class="tr-header">
							<th>Emissão</th>
							<th>Período</th>
							<th>Vencimento</th>
							<th>Valor</th>
							<th>Status</th>
							<th width="30"></th>
						</tr>
					</tbody>

					<tbody>
						<tr v-if="invoices.length === 0">
							<td colspan="6">
								<div class="text-center text-hover-primary my-2">
									<b-spinner class="align-middle" style="margin-right: 10px;" small></b-spinner>
									<strong>Carregando...</strong>
								</div>
							</td>
						</tr>

						<InvoicesTableRow v-else v-for="(invoice, i) in invoices" :key="i" :invoice="invoice" />
					</tbody>
				</b-table-simple>
			</b-card>

			<!-- paginação-->
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
	name: "SubscriptionView",
	components: {
		InvoicesTableRow: () => import('@/components/Subscription/InvoicesTableRow.vue')
	},
	mounted() {
		const isLoading = this.$loading.show()
		this.InfoSubscription().finally(() => {
			this.dataLoaded = true
			isLoading.hide()
			this.getInvoices(this.subscription_id)
		})
	},

	data() {
		return {
			dataLoaded: false,
			loading_invoices: true,
		}
	},

	computed: {
		...mapState('Subscriptions', ['clinic', 'subscription', 'subscription_id', 'invoices']),
		...mapGetters('Subscriptions', ['planSubscribe', 'nextBilling', 'createAt', 'paymentMethod', 'seats', 'currentUsers'])
	},

	methods: {
		...mapActions('Subscriptions', ['getSubscription', 'getInvoices']),

		async InfoSubscription() {
			return await this.getSubscription()
		}
	}
}
</script>

<style lang="scss" scoped>
h3 {
	font-size: 18px;
	line-height: 28px;
}

.card-subscription {
	border: 1px solid var(--neutral-200);
}

.card-body {
	h4 {
		font-weight: 700 !important;
		font-size: 21px;
		margin-bottom: 0;

		img {
			float: right;
		}

		a {
			text-decoration: none;
			color: #305BF2 !important;
		}

		a:hover {
			text-decoration: dashed;
			color: #0b374d !important;
		}
	}

	h4.green { color: #069763 !important; }
	h4.email { color: #7F91D2 !important; }
}

.row-layout {
	margin-bottom: 30px;
}

.table{
	margin-bottom: 0;

	tbody, tr{
		border-top: none !important;
		border-bottom: none !important;

		th {
			background-color: #F4F5FB;
			border-bottom: none !important;
			font-size: 15px;
			color: var(--type-active);
		}

		th, td {
			padding: 14px !important;
			vertical-align: middle;
			border-top: none;
		}
	}
}


.card-table{
	border: 1px solid var(--neutral-200);
	.card-body {
		padding: 0;
	}
}

</style>
